import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@ww/react-ui-components';
import StoresContext from 'stores';
import GqlClientProvider from 'utils/gql';
import config from 'config';
import { Login } from '@ww/login-ui-components';

function getFavicon() {
  return document.getElementById('favicon');
}

function App() {
  useEffect(() => {
    const favicon = getFavicon();
    if (favicon) favicon.href = config.favicon;
  });

  return (
    <Router>
      <div className="App">
        <ThemeProvider>
          <StoresContext>
            <GqlClientProvider>
              <Login prefix='/' />
            </GqlClientProvider>
          </StoresContext>
        </ThemeProvider>
      </div>
    </Router>
  );
}

export default App;
