import React, { useContext, useMemo } from 'react';
import { ApolloClient, InMemoryCache, createHttpLink, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import config from '../config';
import { LoginContext } from '@ww/react-ui-components';

function createClient(token) {
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(
      createHttpLink({
        uri: config.api.url
      })
    ),
    cache: new InMemoryCache()
  });

  return client;
}

function GqlClientProvider({ children }) {
  const {
    state: { token }
  } = useContext(LoginContext);
  const client = useMemo(() => createClient(token), [token]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default GqlClientProvider;
