import merge from 'lodash/merge';
import all from './env/all';
import production from './env/production';
import qa from './env/qa';
import development from './env/development';
import productionOceanTech from './env/productionOceanTech';
import productionCadilac from './env/productionCadilac';

const DEFAULT_CONFIG = 'development';

let currentEnv;

switch (window.location.host) {
  case productionOceanTech.host: {
    currentEnv = 'productionOceanTech';
    break;
  }
  case productionCadilac.host: {
    currentEnv = 'productionCadilac';
    break;
  }
  default: {
    currentEnv = process.env.REACT_APP_DEPLOY_ENV || DEFAULT_CONFIG;
  }
}

const environments = {
  production,
  qa,
  development,
  productionOceanTech,
  productionCadilac
};

export { currentEnv };
export default merge({}, all, environments[currentEnv]);
